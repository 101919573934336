/***
 *                                                                            
 *    ,------.                ,--.                  ,--.  ,--.                
 *    |  .--. ' ,--,--. ,---. `--',--,--,  ,--,--.,-'  '-.`--' ,---. ,--,--,  
 *    |  '--' |' ,-.  || .-. |,--.|      \' ,-.  |'-.  .-',--.| .-. ||      \ 
 *    |  | --' \ '-'  |' '-' '|  ||  ||  |\ '-'  |  |  |  |  |' '-' '|  ||  | 
 *    `--'      `--`--'.`-  / `--'`--''--' `--`--'  `--'  `--' `---' `--''--' 
 *                     `---'                                                  
 */

.wj-pagination {
    text-align: center;   
	margin: 1rem 0;
}
.wj-pagination a, .wj-pagination span {
    padding: 7px 18px;
    border: 1px solid #eee;
    margin-left: -5px;
    margin-right: -5px;
    background-color: #ffffff;
    display: inline-block;
}
.wj-pagination a:hover {    
    background-color: #f1f1f1;
    color: $color;
}