@charset "utf-8";
$base-ff:          'Avenir Next', 'Avenir', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Work Sans', sans-serif;
$head-ff:          'Avenir Next', 'Avenir', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Work Sans', sans-serif;
$system-ff:		   -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
$base-fs:          20px;
$base-fw:          400;
$small-fs:         $base-fs * 0.775;
$base-lh:          1.6;
$color:            #444;
$h-color:          #eee;
$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);
$brand-color: 	   #6a5acd;
$brand-color: 	   #83ce2c;
$brand-color:      #2195D3;


// Width of the content area
$content-width:    800px;
$on-palm:          600px;
$on-laptop:        800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "loader",
        "google-fonts",
        "bootstrap",
        "base",
        "style",
        "latest-posts",
        "post",
        "toc",
        "table",
        "contact",
        "search",
        "pagination",
        "syntax-highlighting",
        //"breadcrumbs",
        "recent",
        "author",
        "share",
        "custom"

;
