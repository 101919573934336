table {
  margin: 15px 0;
  border-collapse: collapse;
  width: 100%;
  padding: 0; }
  table tr {
    border-top: 1px solid $grey-color;
    background-color: white;
    margin: 0;
    padding: 0; }
    table tr:nth-child(2n) {
      background-color: #f8f8f8; }
    table tr th {
      font-weight: bold;
      border: 1px solid $grey-color;
      text-align: left;
      margin: 0;
      padding: 6px 13px; }
    table tr td {
      border: 1px solid $grey-color;
      text-align: left;
      margin: 0;
      padding: 6px 13px; }
    table tr th :first-child, table tr td :first-child {
      margin: 0; }
    table tr th :last-child, table tr td :last-child {
      margin: 0; }

