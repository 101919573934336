/***
 *                                                                                             
 *     ,-----.                 ,--.                  ,--.      ,------.                        
 *    '  .--./ ,---. ,--,--, ,-'  '-. ,--,--. ,---.,-'  '-.    |  .---',---. ,--.--.,--,--,--. 
 *    |  |    | .-. ||      \'-.  .-'' ,-.  || .--''-.  .-'    |  `--,| .-. ||  .--'|        | 
 *    '  '--'\' '-' '|  ||  |  |  |  \ '-'  |\ `--.  |  |      |  |`  ' '-' '|  |   |  |  |  | 
 *     `-----' `---' `--''--'  `--'   `--`--' `---'  `--'      `--'    `---' `--'   `--`--`--' 
 *                                                                                             
 */

form.wj-contact {
  padding: 0em;
  margin: 1em 0;
  @include media-query($on-palm) {
    padding: 0;
  }

  input[type="text"],
  input[type="email"],
  textarea[type="text"] {
    width: 100%;
    vertical-align: middle;
    margin-bottom: 1em;
    padding: 0.5em;
    font-weight: lighter;
    border-style: solid;
    border-color: $grey-color;
    outline-color: $brand-color;
    border-width: 1px;
    border-radius: 3px;
    transition: box-shadow 0.2s ease;
  }

  input[type="submit"] {
    outline: none;
    color: white;
    padding: 8px 25px;
    background-color: $brand-color;
    margin: 0.25em 0 0 0;
    cursor: pointer;
  }
}

.map {
  iframe {
    height: 300px;
    width: 100%;
    border: 2px solid $brand-color;
  }

  @include media-query($on-palm) {
    iframe {
      height: 200px;
    }
  }
}
