/***
 *                                                                                                       
 *    ,--.           ,--.,--.          ,--.   ,--.                ,--.    ,------.                ,--.   
 *    |  |,--,--,  ,-|  |`--',--.  ,--.`--' ,-|  |,--.,--. ,--,--.|  |    |  .--. ' ,---.  ,---.,-'  '-. 
 *    |  ||      \' .-. |,--. \  `'  / ,--.' .-. ||  ||  |' ,-.  ||  |    |  '--' || .-. |(  .-''-.  .-' 
 *    |  ||  ||  |\ `-' ||  |  \    /  |  |\ `-' |'  ''  '\ '-'  ||  |    |  | --' ' '-' '.-'  `) |  |   
 *    `--'`--''--' `---' `--'   `--'   `--' `---'  `----'  `--`--'`--'    `--'      `---' `----'  `--'   
 *                                                                                                       
 */


.header-image {
  margin-top: 100px;
  height: 100vh;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-query($on-palm) {
    height: 35vh;
    margin-top: 50px;
  }
}

.post-title {
  font-weight: 900;
  line-height: 1.8em;
  font-size: 5rem;
  text-transform: uppercase;
  bottom: 20%;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);

  @include media-query($on-palm) {
    line-height: 1.5em;
    font-size: 1.6em;
  }

}

.blog-content, .page-container {
  margin-top: 100px;

  @include media-query($on-palm) {
    margin-top: 100px
  }

}

.blog-content, .page-container {

  margin-bottom: 2rem;

  h1 {
    text-transform: none;
    font-family: Georgia, $head-ff;
  }

  article {

    h2, h3, h4, h5, h6 {
      font-weight: 500;
      text-transform: none;
    }

    img, iframe {
      margin: 1em 0;
    }

    > p {
      //text-align: justify;
    }

    p {


      a:not(.btn) {
        text-decoration: none;
        //background: -webkit-linear-gradient(transparent 90%, $brand-color 10%);
        box-shadow: inset 0 0 0 0 $brand-color;
        -webkit-transition: box-shadow ease 500ms;
        -moz-transition: box-shadow ease 500ms;
        transition: box-shadow ease 500ms;
        padding: 1px 2px;

      }
    }
  }
}


.categories {
  margin: 1em 0;

  span {
    a {
      background-color: $brand-color;
      font-size: $small-fs;
      color: #fff;
      padding: 2px 10px;
      border-radius: 2px;

      &:hover {
        background-color: darken($brand-color, 5%);
      }
    }
  }
}

.disqus {
  margin: 2em 0;
}

.post-container, .page-container, .blog-content {
  /*    max-width: 960px;
     margin: 0 auto; */
}

blockquote {
  border-left: 5px solid $brand-color;
  padding-left: 1em;
  margin-left: 1em;
  font-style: italic;
  font-size: 1.2em;
  font-family: Georgia, $base-ff;

  @include media-query($on-palm) {
    font-size: 1.5em;
    padding-left: 0.5em;
    margin-left: 0.5em;
  }
}
